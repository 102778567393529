<template>
    <div class="home">
        <v-card>
            <v-container fluid>
                <v-row><v-col cols="12"></v-col></v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card outlined>
                            <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                                <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                                    POSITION SNAPSHOT
                                </div>
                            </v-card-title>

                            <v-row class="pa-4">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-alert text class="mt-2 mb-0" color="info" icon="mdi-cloud-alert" border="left">
                                        <p class="mb-0">A open position snapshot is recorded every 1 minutes</p>
                                        <p class="mb-0"><strong>1. Select Date</strong>: Choose the date to load snapshot file</p>
                                        <p class="mb-0">
                                            <strong>2. Filter Time</strong>: Select the time (min) to load position.
                                        </p>
                                    </v-alert>
                                    <!-- <Description :name="'Position Snapshot'"/> -->
                                </v-col>
                            </v-row>

                            <v-row class="pa-4">
                                <v-col cols="12" sm="12" md="4" lg="3" xl="3" v-if="brokerMap.length > 1">
                                    <v-autocomplete label="Filter Broker" v-model="selectedBrokerId" :items="brokerMap" item-text="broker"
                                        item-value="broker_id" auto-select-first chips clearable deletable-chips small-chips></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                                    <v-menu v-model="openDatePicker" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="selectedDate" label="Select Date" prepend-icon="mdi-calendar" readonly
                                                v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="selectedDate" @input="openDatePicker = false" ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                                    <v-text-field label="Start Time (Type timestamp to search. Eg: 14:01 => 1401)"
                                        v-model="selectedStartTime" />
                                </v-col>
                                <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                                    <v-text-field label="End Time (Type timestamp to search. Eg: 14:01 => 1401)"
                                        v-model="selectedEndTime" />
                                </v-col>
                            </v-row>

                            <v-row class="pl-4">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-btn dense color="primary" v-on:click="loadSnapshotFileList()">List Snapshot</v-btn>
                                </v-col>
                            </v-row>

                            <v-row class="pa-4">
                                <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                                    <v-autocomplete label="Filter Time (Type timestamp to search. Eg: 14:01 => 1401)"
                                        v-model="selectedFileName"
                                        :items="tableData.dataSource" ref="autocomplete" item-text="displayTime" :cache-items="true"
                                        item-value="file" auto-select-first chips clearable small-chips></v-autocomplete>
                                </v-col>
                            </v-row>
                            
                        </v-card>
                    </v-col>
                </v-row>
                <!-- v-on:change="loadSnapshotSummary(file)" -->
                <!-- <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-btn dense color="primary" v-on:click="loadSnapshotFileList()" :loading="btnLoading">List Snapshot</v-btn>
                    </v-col>
                </v-row> -->

                <!-- <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-text-field class="mt-n2 mb-2" v-model="tableData.search"
                            append-icon="mdi-search" label="Search" single-line hide-details>
                        </v-text-field>
                        <v-data-table dense :search="tableData.search" :headers="tableData.headers"
                            :items="tableData.dataSource" class="elevation-1" :single-select="false"
                            v-model="tableData.selectedData" :items-per-page="tableData.itemsPerPage">

                            <template v-slot:item.file="{ item }">{{ formatFile(item.file) }}</template>

                            <template v-slot:item.action="{ item }">
                                <v-btn v-on:click="loadSnapshotSummary(item.file)" small :loading="btnLoadingSummary">Load</v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row> -->

                <v-row><v-col cols="12"></v-col></v-row>

                <v-row  v-if="loadSummaryTable === 1">
                    <v-col cols="12">
                        <v-card outlined>
                            <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                                <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                                    RESULT
                                </div>
                                <div class="text-right" style="margin-right: 0; width: 50%;">
                                    <download-excel :data="tableData" :name="'Search'">
                                        <v-icon color="green" dark dense>
                                            mdi-microsoft-excel
                                        </v-icon>
                                    </download-excel>
                                </div>
                            </v-card-title>

                            <v-row class="pa-4">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    The following datatable shows the book summary at {{ formatFile(snapshotDatetime) }}
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-tabs>
                                        <v-tab v-on:click="changeTab(item)" v-for="item in summaryBookList" :key="item">{{ item }}</v-tab>
                                    </v-tabs>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-text-field class="mt-n2 mb-2" v-model="summaryBookData.search" append-icon="mdi-search" label="Search" single-line hide-details>
                                    </v-text-field>
                                    <v-data-table v-if="summaryBookData.load === 1" dense :search="summaryBookData.search" :headers="summaryBookData.headers" :items="summaryBookData.dataSource" class="elevation-1"
                                        :single-select="false" v-model="summaryBookData.selectedData"
                                        :single-expand="true" :expanded.sync="expanded"
                                        @click:row="rowClicked" item-key="Symbol"
                                        :items-per-page="summaryBookData.itemsPerPage">

                                            <template v-slot:expanded-item="{ headers, item }">
                                                <td :colspan="headers.length">
                                                    <v-text-field
                                                        class="mr-4 mt-n2 mb-5" v-model="searchLogin" append-icon="mdi-search"
                                                        label="Search Login" single-line hide-details></v-text-field>

                                                    <v-data-table dense :search="searchLogin" :headers="subtable.headers"
                                                        :items="subtable.dataSource" v-if="loadSubTable" item-key="login"
                                                        class="elevation-1" :items-per-page="10" :loading="subtable.tableLoading"
                                                        loading-text="Loading...It might take a bit loger.Please wait">

                                                        <!-- <template v-slot:item.login="{ item }">
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <span v-bind="attrs" v-on="on" class="hover-login-tooltip"
                                                                        v-on:mouseover="loginDetailHandler().load(item.login)"
                                                                        v-on:mouseleave="loginDetailHandler().remove()">
                                                                        {{ item.login }}
                                                                    </span>
                                                                </template>
                                                                <span v-html="formatToolTip(loginDetail)"></span>
                                                            </v-tooltip>
                                                        </template> -->

                                                        <template v-slot:item.volume_long="{ item }">
                                                            {{ item.volume_long.toFixed(2) }}
                                                        </template>

                                                    </v-data-table>
                                                </td>
                                            </template>

                                    </v-data-table>
                                </v-col>
                            </v-row>

                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import * as apiSnapshotTradesOpenList from "@components/api/pk/snapshot/snapshot-trades-open-list";
import * as apiSnapshotTradesOpenSummary from "@components/api/pk/snapshot/snapshot-trades-open-summary";
import * as apiSnapshotTradesOpenDetail from "@components/api/pk/snapshot/snapshot-trades-open-detail";
import { permission } from "@components/mixins/permission";
import { snackbar } from "@components/mixins/snackbar";
import { mapState } from "vuex";

export default {
    components: { },
    mixins: [permission, snackbar],
    data () {
        return {
            selectedStartTime: "0113",
            selectedEndTime: "0211",
            selectedFileName: "",
            expanded: [],
            selectedBrokerId: -1,
            selectedBroker: "",
            selectedDate: new Date().toISOString().substr(0, 10),
            openDatePicker: false,
            tableData: {
                headers: [{ text: "File", value: "file" }, { text: "", value: "action" }],
                dataSource: [],
                itemsPerPage: 10,
                search: ""
            },
            btnLoading: false,
            openTradesList: [],
            openTradesSummary: {},
            btnLoadingSummary: false,
            summaryBookList: [],
            summaryBookData: {
                headers: [
                    { text: "Symbol", value: "Symbol" },
                    { text: "Buy", value: "Buy" },
                    { text: "Sell", value: "Sell" },
                    { text: "Net", value: "Net" },
                    { text: "Profit", value: "Profit" }
                ],
                load: 0,
                dataSource: [],
                itemsPerPage: 20,
                search: ""
            },
            subtable: {
                headers: [
                    { text: "Login", value: "Login" },
                    { text: "Buy", value: "Buy" },
                    { text: "Sell", value: "Sell" },
                    { text: "Net", value: "Net" },
                    { text: "Profit", value: "Profit" }
                ],
                dataSource: [],
                itemsPerPage: 20,
                search: "",
                tableLoading: false

            },
            tabKey: "",
            tabData: [],
            snapshotDatetime: "",
            snapshotDate: "",
            snapshotTime: "",
            loadSummaryTable: 0,
            searchLogin: "",
            selectedBook: "",
            snapshotSymbol: "",
            loadSubTable: false,
            currentExpanded: ""
        };
    },
    computed: {
        ...mapState(["brokerMap"])
    },
    watch: {
        selectedBrokerId (nv, ov) {
            this.selectedFileName = "";
            this.loadSnapshotFileList();
        },
        selectedDate (nv, ov) {
            this.selectedFileName = "";
            this.loadSnapshotFileList();
        },
        selectedFileName (nv, ov) {
            if (nv !== null && nv !== undefined && nv !== "") {
                this.loadSnapshotSummary(nv);
            }
        }
    },
    methods: {
        clearCachedItems () {
            this.$refs.autocomplete.cachedItems = [];
        },
        /**
         * 获取仓位组别的详情
         * @return {[type]} [description]
         */
        loadSnapshotSummaryDetail () {
            const self = this;
            const params = {
                broker_id: self.selectedBrokerId,
                target_date: self.snapshotDate,
                time: self.snapshotTime,
                symbol: self.snapshotSymbol,
                book: self.selectedBook
            };
            self.subtable.tableLoading = true;
            self.subtable.dataSource = [];
            apiSnapshotTradesOpenDetail.fetch(params).then(res => {
                self.subtable.dataSource = res.data;
                self.subtable.tableLoading = false;
            });
        },
        /**
         * Event listener on aprent table row clicked
         * @param  {[type]} data [description]
         * @return {[type]}      [description]
         */
        rowClicked (data) {
            console.log(data);
            this.expanded = [];
            this.snapshotSymbol = data.Symbol;
            this.loadSubTable = false;
            // // get current symbol under logins of current book summary
            this.loadSnapshotSummaryDetail();

            if (this.currentExpanded === "") {
                this.currentExpanded = data.Symbol;
                this.expanded = [data];

                this.$nextTick(() => {
                    this.loadSubTable = true;
                });
            } else if (this.currentExpanded !== data.Symbol) {
                this.currentExpanded = data.Symbol;
                this.expanded = [data];

                this.$nextTick(() => {
                    this.loadSubTable = true;
                });
            } else {
                this.currentExpanded = "";
            }
        },
        /**
         * 检测tab改动
         * @param  {[type]} key [description]
         * @return {[type]}     [description]
         */
        changeTab (key) {
            this.summaryBookData.load = 0;
            this.$nextTick(() => {
                this.summaryBookData.load = 1;
            });
            this.selectedBook = key;
            this.summaryBookData.dataSource = this.openTradesSummary[key];
        },
        /**
         * 从S3上面加载snapshot
         * @return {[type]} [description]
         */
        loadSnapshotFileList () {
            const self = this;

            const params = { target_date: self.selectedDate, broker_id: self.selectedBrokerId };

            console.log(params);

            self.btnLoading = true;

            self.$store.commit("globalLoadingHandler", true);

            self.tableData.dataSource = [];
            self.clearCachedItems();

            apiSnapshotTradesOpenList.fetch(params).then(res => {
                setTimeout(() => {
                    self.btnLoading = false;
                    self.snackBarSuccess("Load file list successfully");

                    for (let i = 0; i < res.data.length; i++) {
                        self.tableData.dataSource.push({
                            file: res.data[i],
                            displayTime: self.formatFile(res.data[i])
                            // file: self.selectedDate + " " + res.data[i]
                        });
                    }
                    // if (res.data.length !== 0) self.selectedFileName = self.selectedDate + " " + res.data[0];
                    self.$store.commit("globalLoadingHandler", false);
                }, 1000);
            });
        },
        /**
         * 获取所有snapshot的文件名字
         * @param  {[type]} ts [description]
         * @return {[type]}    [description]
         */
        loadSnapshotSummary (ts) {
            const self = this;
            // let splitData = ts.split(" ");
            const date = self.selectedDate;
            const time = ts.replace(":", "").replace(".csv", "");
            const params = { broker_id: self.selectedBrokerId, target_date: date, time: time };
            console.log(params);

            self.snapshotDate = date;
            self.snapshotTime = time;
            self.btnLoadingSummary = true;
            self.snapshotDatetime = ts;
            self.loadSummaryTable = 0;

            self.summaryBookData.load = 0;

            self.summaryBookList = [];
            self.$store.commit("globalLoadingHandler", true);

            apiSnapshotTradesOpenSummary.fetch(params).then(res => {
                self.openTradesSummary = res.data;
                let count = 0;
                for (const item in res.data) {
                    self.summaryBookList.push(item);
                    if (count === 0) self.summaryBookData.dataSource = res.data[item];
                    count += 1;
                }
                self.btnLoadingSummary = false;
                self.snackBarSuccess("Load net summary successfully");
                self.loadSummaryTable = 1;
                self.$store.commit("globalLoadingHandler", false);
                self.$nextTick(() => {
                    self.summaryBookData.load = 1;
                });
            });
        },
        chunk (str, n) {
            const ret = [];
            let i;
            const len = str.length;
            for (i = 0; i < len; i += n) {
                ret.push(str.substr(i, n));
            }
            return ret;
        },
        /**
         * 重新排版文件名字
         * @return {[type]} [description]
         */
        formatFile (file) {
            // let splitData = file.split(" ");
            // let date = splitData[0];
            // let time = splitData[1].replace(".csv", "");
            const time = file.replace(".csv", "").replace("_LIVE1", "").replace("_LIVE2", "").split("_MT4")[0];
            const filename = this.chunk(time, 2).join(":");
            // return date + " " + filename;
            return filename;
        }
    },
    mounted () {
        const brokerPermission = this.loadDefaultBrokerData();
        // this.selectedBrokerId = brokerPermission.selectedBrokerId;
        this.selectedBrokerId = 10;
        // this.selectedBroker = brokerPermission.selectedBroker;
        this.selectedBroker = 'FPG';

        this.loadSnapshotFileList();
    }
};
</script>
